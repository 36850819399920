<template>
  <the-header></the-header>
  <landing-page></landing-page>
  <the-footer></the-footer>
</template>

<script>
import TheHeader from "./components/layout/TheHeader";
import TheFooter from "./components/layout/TheFooter";
import LandingPage from "./pages/LandingPage";

export default {
  name: 'App',
  components: {
    TheHeader,
    TheFooter,
    LandingPage
  }
}
</script>

<style>

* {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
}

body {
  margin: 0;
  background: #f6f6f6;
}
hr {
  border: 0;
  height: 0;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid transparent;
}

.row {
  display: flex;
  flex-wrap: wrap;
}

.col-2 {
  flex: 50%;
}

.col-3 {
  flex: 33.33%;
}

.col-4 {
  flex: 25%;
}

@media only screen and (max-width: 992px) {
  [class*="col-"] {
    flex: 50%;
  }
}

@media screen and (max-width: 576px) {
  [class*="col-"] {
    flex: 100%;
  }
  .row {
    flex-direction: column;
  }
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

.center-screen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

</style>
