<template>
  <div class="center-screen">
    <div class="row">
      <base-dialog
        :show="!!error"
        title="An error occurred"
        @close="handleError"
      >
        <p>{{ error }}</p>
      </base-dialog>
      <base-dialog :show="connecting" title="Connecting to Meter(s)" fixed>
        <base-spinner></base-spinner>
      </base-dialog>
      <br />
      <div class="box-margin">
        <base-button v-if="!client.connected" @click="createConnection">
          Connect to Meter(s)
        </base-button>
        <base-button
          v-if="client.connected && !subscribeSuccess"
          @click="doSubscribe"
        >
          Start Receive Data
        </base-button>
        <base-button v-if="subscribeSuccess" @click="fullDisconnect">
          Stop Receive Data
        </base-button>
        <base-activity
          v-if="subscribeSuccess"
          mode="dot-elastic"
          align="center"
        ></base-activity>
      </div>
    </div>
    <hr width="85%" />
    <div class="row" style="margin-left: 1rem;">
      <tabs-main :options="{ defaultTabHash: 'first-tab' }" width="90%">
        <tab-main id="first-tab" name="MQTT_RT_DATA">
          <div>
            <DataTable
              :data="tableRTData"
              :options="{ order: [1, desc] }"
              class="display table-responsive"
              width="100%"
            >
              <thead>
                <tr>
                  <th>DeviceID</th>
                  <th>DT</th>
                  <th>Date/Time</th>
                  <th>uab</th>
                  <th>ubc</th>
                  <th>uca</th>
                  <th>f</th>
                  <th>pdm</th>
                  <th>qdm</th>
                  <th>sdm</th>
                </tr>
              </thead>
            </DataTable>
          </div>
        </tab-main>
        <tab-main id="second-tab" name="MQTT_ENY_NOW">
          <div>
            <DataTable
              :data="tableENYData"
              class="display"
              :options="{ order: [1, desc] }"
              width="100%"
            >
              <thead>
                <tr>
                  <th>DeviceID</th>
                  <th>Date/Time</th>
                  <th>zygsz</th>
                  <th>uathd</th>
                  <th>ucthd</th>
                  <th>iathd</th>
                  <th>icthd</th>
                  <th>iaxbl3</th>
                  <th>icxbl3</th>
                  <th>iaxbl5</th>
                  <th>icxbl5</th>
                  <th>iaxbl7</th>
                </tr>
              </thead>
            </DataTable>
          </div>
        </tab-main>
        <tab-main id="third-tab" name="ALL_DATA">
          <div>
            <DataTable
              :data="tableAllData"
              class="display"
              :options="{ order: [1, desc] }"
              style="max-width: 950px"
            >
              <thead>
                <tr>
                  <th>Topic</th>
                  <th>Date/Time</th>
                  <th>All Data</th>
                </tr>
              </thead>
            </DataTable>
          </div>
        </tab-main>
      </tabs-main>
    </div>
  </div>
</template>

<script>
import DataTable from "datatables.net-vue3";
import DataTablesLib from "datatables.net";
import mqtt from "mqtt";
import { useToast } from "vue-toastification";

DataTable.use(DataTablesLib);

export default {
  components: {
    DataTable,
  },
  data() {
    return {
      tableRTData: [],
      tableENYData: [],
      tableAllData: [],
      connection: {
        protocol: "ws",
        host: "mqtt.wss.maple.ltd",
        // ws: 8083; wss: 8084
        port: 8083,
        endpoint: "",
        // for more options, please refer to https://github.com/mqttjs/MQTT.js#mqttclientstreambuilder-options
        clean: true,
        connectTimeout: 30 * 1000, // ms
        reconnectPeriod: 4000, // ms
        clientId: "kamsa_iot_" + Math.random().toString(16).substring(2, 8),
        // auth
        username: "kamsaE",
        password: "Kamsa@Energy#2023",
      },
      subscription: {
        topic: "+",
        qos: 0,
      },
      publish: {
        topic: "topic/kamsatest",
        qos: 0,
        payload: '{ "msg": "Hello, I am testing from the Kamsa." }',
      },
      receiveNews: "",
      qosList: [0, 1, 2],
      client: {
        connected: false,
      },
      subscribeSuccess: false,
      connecting: false,
      retryTimes: 0,
      error: null,
    };
  },
  mounted() {
    //return moment(String(value)).format(‘MM/DD/YYYY hh:mm’)
    //let dt = this.$moment();
    //console.log(dt);
    //let todayDate = new Date('20230216105500').toISOString().slice(0, 10);
    //let todayDate = new Date(20230216105500).toISOString();
    // let todayDate = new Date().toLocaleDateString('en-us', { weekday:"short", year:"numeric", month:"short", day:"numeric"})
    // console.log(todayDate);
  },
  methods: {
    initData() {
      this.client = {
        connected: false,
      };
      this.retryTimes = 0;
      this.connecting = false;
      this.subscribeSuccess = false;
    },
    handleOnReConnect() {
      this.retryTimes += 1;
      if (this.retryTimes > 5) {
        try {
          this.client.end();
          this.initData();
          this.$message.error("Connection maxReconnectTimes limit, stop retry");
        } catch (error) {
          this.$message.error(error.toString());
          this.error = error.toString();
        }
      }
    },
    createConnection() {
      try {
        this.connecting = true;
        const { protocol, host, port, endpoint, ...options } = this.connection;
        const connectUrl = `${protocol}://${host}:${port}${endpoint}`;
        this.client = mqtt.connect(connectUrl, options);
        if (this.client.on) {
          this.client.on("connect", () => {
            this.connecting = false;
            console.log("Connection succeeded!");
          });
          this.client.on("reconnect", this.handleOnReConnect);
          this.client.on("error", (error) => {
            console.log("Connection failed", error);
            this.error = "Connection failed";
          });
          this.client.on("message", (topic, message) => {
            this.receiveNews = this.receiveNews.concat(message);

            let message_parsed = JSON.parse(message);

            console.log(`Received message ${message} from topic ${topic}`);

            if (topic == "MQTT_RT_DATA") {
              console.log("MQTT_RT_DATA");
              console.log(message_parsed.ubc);

              // let dt = this.$moment.(message_parsed.time).format('yyyy-MM-DD');
              // console.log(dt);

              let myArray = message_parsed.time.split("");

              let year = myArray[0] + myArray[1] + myArray[2] + myArray[3];
              let month = myArray[4] + myArray[5];
              let day = myArray[6] + myArray[7];
              let hour = myArray[8] + myArray[9];
              let min = myArray[10] + myArray[11];
              let sec = myArray[12] + myArray[13];
              let dateString =
                year +
                "-" +
                month +
                "-" +
                day +
                " " +
                hour +
                ":" +
                min +
                ":" +
                sec;

              let todayDate = new Date(dateString).toLocaleString("en-US", {
                weekday: "short",
                year: "numeric",
                month: "short",
                day: "numeric",
                hour: "numeric",
                minute: "numeric",
                second: "numeric",
                hour12: true,
              });

              let finalRTdata = [
                message_parsed.id,
                message_parsed.time,
                todayDate,
                message_parsed.uab,
                message_parsed.ubc,
                message_parsed.uca,
                message_parsed.f,
                message_parsed.pdm,
                message_parsed.qdm,
                message_parsed.sdm,
              ];

              this.tableRTData.push(finalRTdata);
            } else if (topic == "MQTT_ENY_NOW") {
              console.log("MQTT_ENY_NOW");
              console.log(message_parsed.zygsz);

              let finalENYdata = [
                message_parsed.id,
                message_parsed.time,
                message_parsed.zygsz,
                message_parsed.uathd,
                message_parsed.ucthd,
                message_parsed.iathd,
                message_parsed.icthd,
                message_parsed.iaxbl3,
                message_parsed.icxbl3,
                message_parsed.iaxbl5,
                message_parsed.icxbl5,
                message_parsed.iaxbl7,
              ];

              this.tableENYData.push(finalENYdata);
            } else {
              console.log(message_parsed);
            }

            let finalAllData = [topic, message_parsed.time, message];
            this.tableAllData.push(finalAllData);
          });
        }
      } catch (error) {
        this.connecting = false;
        console.log("mqtt.connect error", error);
        this.error = "mqtt.connect error";
      }
    },
    doSubscribe() {
      const { topic, qos } = this.subscription;
      this.client.subscribe(topic, { qos }, (error, res) => {
        if (error) {
          console.log("Subscribe to topics error", error);
          this.error = "Subscribe to topics error";
          return;
        }
        this.subscribeSuccess = true;
        console.log("Subscribe to topics res", res);
        const toast = useToast();
        toast.info("You will start receiving data shortly.");
      });
    },
    doUnSubscribe() {
      const { topic } = this.subscription;
      this.client.unsubscribe(topic, (error) => {
        if (error) {
          console.log("Unsubscribe error", error);
          this.error = "Unsubscribe error";
        }
      });
    },
    doPublish() {
      const { topic, qos, payload } = this.publish;
      this.client.publish(topic, payload, { qos }, (error) => {
        if (error) {
          console.log("Publish error", error);
          this.error = "Publish error";
        }
      });
    },
    destroyConnection() {
      if (this.client.connected) {
        try {
          this.client.end(false, () => {
            this.initData();
            console.log("Successfully disconnected!");
          });
        } catch (error) {
          console.log("Disconnect failed", error.toString());
          this.error = "Disconnect failed";
        }
      }
    },
    fullDisconnect() {
      this.doUnSubscribe();
      this.destroyConnection();
      const toast = useToast();
      toast.info("You have stopped receiving data.");
    },
    handleError() {
      this.error = null;
    },
  },
};
</script>

<style>
@import "datatables.net-dt";

.tabs-component {
  margin: 4em 0;
}

.tabs-component-tabs {
  border: solid 1px #ddd;
  border-radius: 6px;
  margin-bottom: 5px;
}

@media (min-width: 700px) {
  .tabs-component-tabs {
    border: 0;
    align-items: stretch;
    display: flex;
    justify-content: flex-start;
    margin-bottom: -1px;
  }
}

.tabs-component-tab {
  color: #999;
  font-size: 14px;
  font-weight: 600;
  margin-right: 0;
  list-style: none;
}

.tabs-component-tab:not(:last-child) {
  border-bottom: dotted 1px #ddd;
}

.tabs-component-tab:hover {
  color: #666;
}

.tabs-component-tab.is-active {
  color: #000;
}

.tabs-component-tab.is-disabled * {
  color: #cdcdcd;
  cursor: not-allowed !important;
}

@media (min-width: 700px) {
  .tabs-component-tab {
    background-color: #fff;
    border: solid 1px #ddd;
    border-radius: 3px 3px 0 0;
    margin-right: 0.5em;
    transform: translateY(2px);
    transition: transform 0.3s ease;
  }

  .tabs-component-tab.is-active {
    border-bottom: solid 1px #fff;
    z-index: 2;
    transform: translateY(0);
  }
}

.tabs-component-tab-a {
  align-items: center;
  color: inherit;
  display: flex;
  padding: 0.75em 1em;
  text-decoration: none;
  justify-content: center;
}

.tabs-component-panels {
  padding: 4em 0;
}

@media (min-width: 700px) {
  .tabs-component-panels {
    background-color: #fff;
    border: solid 1px #ddd;
    border-radius: 0 6px 6px 6px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    padding: 4em 2em;
  }
}

.tabs-component-btn {
  cursor: pointer;
  background: #e1ecf4;
  border-radius: 3px;
  border: 1px solid #7aa7c7;
  padding: 4px 8px;
  color: #39739d;
}

.tabs-component-btn:hover {
  background-color: #b3d3ea;
  color: #2c5777;
}

.tabs-component-btn:active {
  background-color: #a0c7e4;
  box-shadow: none;
  color: #2c5777;
}

.box-margin {
  margin: 1rem;
  border: 1px solid #ccc;
  border-radius: 12px;
  padding: 1rem;
  width: 95%; /* or whatever width you want. */
  max-width: 600px; /* or whatever width you want. */
}

/* td {
  max-width: 500px;
} */
</style>
