import { createApp } from "vue";
import App from "./App.vue";

import BaseCard from "./components/ui/BaseCard.vue";
import BaseButton from "./components/ui/BaseButton.vue";
import BaseBadge from "./components/ui/BaseBadge.vue";
import BaseSpinner from "./components/ui/BaseSpinner.vue";
import BaseDialog from "./components/ui/BaseDialog.vue";
import BaseActivity from "./components/ui/BaseActivity.vue";
import {Tabs, Tab} from 'vue3-tabs-component';

import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";


const app = createApp(App);

app.use(Toast);

app.component("base-card", BaseCard);
app.component("base-button", BaseButton);
app.component("base-badge", BaseBadge);
app.component("base-spinner", BaseSpinner);
app.component("base-dialog", BaseDialog);
app.component("base-activity", BaseActivity);

app.component("tabs-main", Tabs);
app.component("tab-main", Tab);

app.mount("#app");
